







































































import Vue from 'vue'
import CreditLine from '../../../../../../store/models/CreditLine'

export default Vue.extend({
  computed: {
    creditLineId(): string {
      return this.$route.params.creditLineId
    },

    creditLine(): CreditLine | null {
      return CreditLine.query().find(this.creditLineId)
    }
  },

  created() {
    if (!this.creditLine) {
      CreditLine.api().fetchById(this.creditLineId, { populate: [] })
    }
  }
})
